import React from 'react';
import { MultipleEntryReferenceEditor } from '@contentful/field-editor-reference';
import { FieldExtensionSDK } from '@contentful/app-sdk';
import { EntryProps, PlainClientAPI, SysLink } from 'contentful-management';
import { useFieldChangeHook } from '../Field.hooks';
import { Loading, Item } from './Variant.sku';
import { findAsync } from '../../utilities';
import {
  EntityList,
  TextInput,
  Card,
  Paragraph,
  Text,
  SkeletonContainer,
  SkeletonBodyText,
  Button,
  Stack,
} from '@contentful/f36-components';

const endpoint = 'https://staging.platform.bombas.com/graphql';
// const endpoint = 'http://localhost:3000/dev/graphql';

interface ProductVariantsFieldProps {
  sdk: FieldExtensionSDK;
  cma: PlainClientAPI;
}

const ProductVariantsField = ({ sdk, cma }: ProductVariantsFieldProps) => {
  const [loadingState, setLoadingState] = React.useState<Loading>(Loading.None);
  const [query, setQuery] = React.useState('');
  const [items, setItems] = React.useState<Array<Item>>([]);
  const [linkedEntries, setLinkedEntries] = React.useState<Array<EntryProps>>([]);
  const [filteredItems, setFilteredItems] = React.useState<Array<Item>>([]);

  sdk.window.startAutoResizer();

  // Helper function to find all linked entries
  const findEntries = async (value: Array<SysLink> | undefined) => {
    const newLinkedEntries: Array<EntryProps> = [];

    await findAsync(
      value,
      async (item) => await cma.entry.get({ entryId: item.sys.id }).then((e) => {
        newLinkedEntries.push(e);
      })
    );

    setLinkedEntries(newLinkedEntries);
  };

  // Run once on start-up to pull the linked entries
  React.useEffect(() => { findEntries(sdk.field.getValue()) }, []);
  // Listen for changes, and re-populate and re-generate the linked entries
  useFieldChangeHook(sdk, findEntries);

  // Compare existing items already linked with the ones given from search, and
  // filter out duplicates.
  React.useEffect(() => {
    if (items?.length > 0) {
      // If we have items, but no linkedEntries yet, we can't do any filtering
      if (linkedEntries?.length < 1) {
        setFilteredItems(items);
      } else {
        // If we have items AND linkedEntries, we can filter!
        const newFilteredItems = items.filter((item) => {
          const foundEntry = linkedEntries.find((entry) => {
            if (entry?.fields?.sku) {
              if (typeof entry?.fields?.sku === 'string') {
                return entry.fields.sku === item.sku;
              } else {
                return entry.fields.sku[sdk.locales.default] === item.sku;
              }
            }

            return false;
          });

          return typeof foundEntry === 'undefined';
        });

        setFilteredItems(newFilteredItems);
      }
    }
  }, [items, linkedEntries, setFilteredItems, sdk]);

  // Search
  React.useEffect(() => {
    if (query !== '') {
      setLoadingState(Loading.Initialized);

      fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: `query itemSearchRaw($query: String!) { itemSearchRaw(query: $query) }`,
          variables: { query },
        }),
      }).then(res => res.json()).then(res => {
        const foundItems = res?.data?.itemSearchRaw || [];
        setItems(foundItems);
        setLoadingState(Loading.Success);
      }).catch(err => {
        console.error(err);
        setLoadingState(Loading.Error)
      });
    }
  }, [query]);

  // Add a variant
  const handleAddVariant = React.useCallback(async (item) => {
    // Check to see if the variant exists before creating
    const possibleEntries = await cma.entry.getMany({
      query: {
        content_type: 'variant',
        'fields.sku': item.sku,
      }
    });

    // If it exists, grab the first instance of it. If not, create a new one.
    const entry = possibleEntries?.total > 0 ? possibleEntries.items[0] : await cma.entry.create(
      {
        contentTypeId: 'variant'
      },
      {
        fields: {
          sku: {
            [sdk.locales.default]: item.sku,
          }
        }
      }
    );

    // Publish the variant
    await cma.entry.publish({ entryId: entry.sys.id }, entry);

    const existingFields = sdk.field.getValue() || [];

    // Merge the existing variants with the newly added variant
    sdk.field.setValue([
      ...existingFields,
      {
        sys: {
          type: 'Link',
          linkType: 'Entry',
          id: entry.sys.id
        }
      }
    ])
  }, [sdk, cma]);

  return (
    <Stack flexDirection="column" fullWidth={true} >
      <Card>
        <Paragraph>
          <Text>
            Search for variants to add to the product:&nbsp;
            <Text fontColor="gray500">
              (SKU, Style Number, Name, or Color)
            </Text>
          </Text>
        </Paragraph>
        <TextInput
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Search..."
        />
      </Card>
      {loadingState === Loading.Initialized && (
        <SkeletonContainer ariaLabel="Loading potential variants">
          <SkeletonBodyText numberOfLines={4}/>
        </SkeletonContainer>
      )}
      {filteredItems?.length > 0 && (
        <EntityList style={{ width: '100%' }}>
          {filteredItems.map((item, index) => {
            const name = item?.name as string || undefined;
            const itemType = item?.type as string || undefined;
            const itemClass = item?.class as string || undefined;

            const info = itemType && itemClass ? `${itemType}, ${itemClass}` : undefined;

            return (
              <div
                key={index}
                style={{
                  position: 'relative'
                }}
              >
                <EntityList.Item
                  title={item.sku}
                  description={name}
                  contentType={info}
                  withThumbnail={false}
                />
                <div style={{
                  position: 'absolute',
                  zIndex: 1,
                  top: 0,
                  right: 0,
                  margin: '.75em'
                }}>
                  <Button onClick={() => handleAddVariant(item)}>Add</Button>
                </div>
              </div>
            );
          })}
        </EntityList>
      )}
      <div style={{ width: '100%' }}>
        <MultipleEntryReferenceEditor
          viewType="link"
          sdk={sdk}
          hasCardEditActions
          isInitiallyDisabled={false}
          parameters={{
            instance: { showCreateEntityAction: true, showLinkEntityAction: true }
          }}
        />
      </div>
    </Stack>
  );
};

export default ProductVariantsField;
