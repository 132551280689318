import React from 'react';
import { FieldExtensionSDK } from '@contentful/app-sdk';
import { PlainClientAPI } from 'contentful-management';
import { TextInput, Card, Text, Tabs, Stack } from '@contentful/f36-components';

interface PageUrlFieldProps {
  sdk: FieldExtensionSDK;
  cma: PlainClientAPI;
}

const emptyDefaultTab = '/pages/';

const PageUrlField = ({ sdk }: PageUrlFieldProps) => {
  const savedUrl = sdk.field.getValue() || '';
  const savedUrlParts = savedUrl.split('/');
  savedUrlParts.shift();
  const tabPath = savedUrlParts.shift();
  const restUrlPath = savedUrlParts.join('/');
  const defaultTab = tabPath ? `/${tabPath}/`.replaceAll('//', '/') : emptyDefaultTab;

  const [url, setUrl] = React.useState(restUrlPath ?? '');
  const [tab, setTab] = React.useState(defaultTab);

  sdk.window.startAutoResizer();

  React.useEffect(() => {
    const fullUrl = `${tab}${url}`.replaceAll('//', '/');

    // Check to make sure we aren't setting it to just the prefixed tab
    if (url && url !== '') {
      sdk.field.setValue(fullUrl);
    } else {
      sdk.field.setValue('');
    }
  }, [sdk, url, tab]);

  return (
    <>
      <Card marginBottom="spacingM">
        <Stack flexDirection="column">
          <Tabs onTabChange={setTab} defaultTab={defaultTab}>
            <Tabs.List>
              <Tabs.Tab panelId="/pages/">Page&nbsp;<Text fontSize="fontSizeS" fontColor="gray500">/pages/:id</Text></Tabs.Tab>
              <Tabs.Tab panelId="/collections/">Collection&nbsp;<Text fontSize="fontSizeS" fontColor="gray500">/collections/:id</Text></Tabs.Tab>
              <Tabs.Tab panelId="/products/">Product&nbsp;<Text fontSize="fontSizeS" fontColor="gray500">/products/:id</Text></Tabs.Tab>
            </Tabs.List>
          </Tabs>
          <TextInput.Group>
           <TextInput isDisabled={true} value={tab} style={{ maxWidth: '150px' }} />
            <TextInput
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              placeholder="womens-no-show-socks"
            />
          </TextInput.Group>
        </Stack>
      </Card>
    </>
  );
};

export default PageUrlField;
